$(function(){
	var targetSelector = '.mix';
	var initialFilter = 'all';
	var hash = window.location.hash.replace(/^#/g, '');
	
	if (hash) {
			initialFilter = '.' + hash;
	}
	
	/**
	 * Updates the URL whenever the current filter changes.
	 *
	 * @param   {mixitup.State} state
	 * @return  {void}
	 */
	function setHash(state) {
		//console.log(state);
		var selector = state.activeFilter;
		var newHash = '#' + selector.replace(/^\./g, '');

		if (selector === targetSelector && window.location.hash) {
				// Equivalent to filter "all", remove the hash

				history.pushState(null, document.title, window.location.pathname); // or history.replaceState()
		} else if (newHash !== window.location.hash && selector !== targetSelector) {
				// Change the hash

				history.pushState(null, document.title, window.location.pathname + newHash); // or history.replaceState()
		}
	}
	

	document.body.addEventListener('htmx:afterSwap', function(evt) {
		if(evt.detail.xhr.status === 200){
			$('#portfolio').mixItUp({
				load: {
					filter: initialFilter
				},
				callbacks: {
						onMixEnd: setHash // Call the setHash() method at the end of each operation
				}		
			});	
						
		}
	});

});